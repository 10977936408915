.parte {
  background-color: #ffffff;
  margin: 10px;
  border-radius: 10px;
  padding: 20px;
  border: solid;
  border-width: 1px;
  border-color: #dddddd;
  box-shadow: 10px 10px 5px #eeeeee;
}
.parte input{
  font-size:1.3em;
  background-color:#f0f0f0;
}
.linhacifra{
  height:2em;
  color: red;
  width:100%;
  position: relative;
}


.fake{
  border: 0.1px solid;
  font-size: 1.3em;
  position:absolute;
  left:0px;
  display: inline-block;
  white-space: pre;
  opacity: 0;
  user-select: none; 

}

.linha-input{
  font-size:1.3em;
  background-color:#f0f0f0;
}

.cifra {
    font-weight: bold;
    color: blue;
    top:10px;
    user-select: none; 
    position: absolute; 
    cursor: pointer; 
    left: 0px;
}

#indicador{
  display: none;
  color: red;
  position: absolute;
}